.container {
  color: grey;
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;
  gap: 5px;
  user-select: none;

  svg {

    path {
      fill: grey;
    }
  }
}