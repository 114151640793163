.light-theme, .dark-theme {

  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --text: var(--black);
  --bg: var(--white);


  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  background: var(--white);

}

.popup {
  border-radius: 3rem;
  padding: 6px;

  background: var(--black);
  width: 9em;

  span {
    display: flex;
    justify-content: center;
    color: var(--white);
    font-size: 1em;
  }
}




.dark-theme {
  --text: var(--white);
  --bg: var(--black);


  .popup {
    background: var(--white);

    span {
      color: var(--black);
    }
  }


}

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  z-index: 2;
}


.dark-theme {
  background: var(--black);
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: min-content;
  flex-direction: row;
  justify-content: space-between;
  font-family: monospace;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 3;

  .left {
    width: 50px;
  }

}

.switch {
  width: 40px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;

  &_enabled {
    justify-content: flex-end;
  }

  .handle {
    width: 18px;
    height: 18px;
    background-color: var(--black);
    border-radius: 20px;
  }
}

.dark-theme {
  background: var(--black);

  .switch {
    background-color: rgba(255, 255, 255, 0.2);

    .handle {
      background-color: var(--white);
    }
  }

  span {
    color: var(--white);
  }
}

.logo {
  margin-top: 15vh;
  position: relative;
  width: 100%;
  align-self: center;
  max-width: 200px;
  transform: scale(1.2);
  transition: all 100ms;

  &:hover {
    transform: scale(1.2);

  }

}

.letter {
  width: 80%;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 1;
}

.letter-shadow {
  width: 80%;
  position: absolute;
  top: 5px;
  left: 25px;
  z-index: 0;

  path {
    fill: rgba(0, 0, 0, .2) !important;
  }

}


.hedonism {
  position: absolute;
  top: 170px;
  right: 12px;
  color: rgba(0, 0, 0, 1);
  font-size: 1.8em;
}

.team {
  position: absolute;
  top: 203px;
  right: 12px;
  font-weight: 900;
  color: rgba(0, 0, 0, 1);
  font-size: 1.2em;
}

.ParticleBackground {
  position: fixed;
  top: 0;
  z-index: 1;
}

.socials {
  display: flex;
  gap: 10px;

  svg {
    height: 25px;
    width: 25px;
    cursor: pointer;
    transition: all 300ms;

    &:hover {
      path {
        fill: rgba(0, 0, 0, .5);
      }
    }

  }
}





.dark-theme {
  .socials {
    path {
      fill: var(--white);
    }

    svg {
      &:hover {
        path {
          fill: rgba(255, 255, 255, .5);
        }
      }

    }

  }
}


.achievements {
  margin: calc(20vh + 200px) auto 5rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.achievement {
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 140px;
  align-self: center;

  &-div {
    display: flex;
    height: 150px;
    width: 150px;
  }

  &__value {
    font-size: 1.5em;
  }

  &__text {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.1em;
    font-family: monospace;
  }
}


.dark-theme {
  .letter {
    path {
      fill: rgba(255, 255, 255, 1) !important;
    }
  }

  .letter-shadow {
    path {
      fill: rgba(255, 255, 255, .2) !important;
    }
  }

  .hedonism {
    color: var(--white);
  }

  .team {
    color: var(--white);
  }

  .achievements {
    div {
      color: var(--white);
    }
  }

}